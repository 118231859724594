import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AlertDialog from '../public/AlertDialog';
import useForm from "../public/useForm";
import { validator } from "../public/Validator";
import { Navigate } from "react-router-dom";
import { authenticate } from '../public/PrivateRouter';
import { Paper } from '@mui/material';
// import { useCookies } from 'react-cookie';

const theme = createTheme();

export default function ModifyPassword() {
    const [alert, setAlert] = React.useState(false);
    const [alertInfo, setAlertInfo] = React.useState("");

    const [isSubmit, setIsSubmit] = React.useState(false);
    const [isGoBack, setIsGoBack] = React.useState(false);

    // const [cookies, setCookie] = useCookies(['leimode']);

    // const [remember, setRemember] = React.useState(false);

    const initState = {
        password_old: "",
        password: "",
        password_cf: ""
    };

    async function CheckPermise(data) {
        const requestOptions = {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'authorization': sessionStorage.getItem('token'),
            },
            body: JSON.stringify(data)
        };

        await fetch(process.env.REACT_APP_BASEURL+"/api/users/changepassword", requestOptions)
            .then(res => {
                if (res.status !== 200) {
                    {
                        console.log("not 200 error")
                    }
                } else {
                    res.json().then(repData => {
                        console.log(repData);
                        setAlertInfo(repData.message);
                        setAlert(true);
                        if (repData.code == '200') {
                            console.log('修改成功！！！');
                            // Navigate({to:'/'});
                            setIsSubmit(true);
                        }
                        //state.name='';
                    })
                }
            }, error => {
                console.log(error)
            })
    }

    const submit = () => {
        console.log("Submited");
        if (state.password !== state.password_cf) {
            setAlert(true);
            setAlertInfo('两次输入的新密码不一致');
            errors.password = 'error';
            errors.password_cf = 'error';
            return;
        }

        var postData = { password_old: state.password_old, password: state.password };
        CheckPermise(postData);
    };

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        state,
        errors,
        countryCode
    } = useForm({
        initState,
        callback: submit,
        validator
    });

    // return (<Box display='flex' flexDirection='column' bgcolor='yellow' sx={{height:'100vh',width:'100vw'}}>
    //     <Box bgcolor='red' height='50%'>dddd</Box>
    //     <Box bgcolor='green' height='50%'>dddd</Box>
    // </Box>);

    return (<ThemeProvider theme={theme}>
        <AlertDialog
            alertstate={alert}
            handlechange={() => {
                setAlert(false);
                if (isSubmit) {
                    setIsGoBack(true);
                }
                // let isOk = () => {
                //     setIsSubmit(true);
                //     // setShowLoginCallback();
                // }
                // authenticate() ? isOk() : setIsSubmit(false);
            }}
            alertinfo={alertInfo}
            isOnClose={false}
        />

        {isGoBack && (<Navigate to='/' />)}
        <Box display='flex' sx={{  minHeight: '89vh', width: '100vw' }} alignItems='center' justifyContent='center'>
            <Paper elevation='3'>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            // marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 5,
                            mb: 5,
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            修改密码
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password_old"
                                label="旧密码"
                                type="password"
                                id="password_old"
                                autoComplete="current-password"
                                defaultValue={state.password_old}
                                onChange={handleChange}
                                error={errors.password_old ? true : false}
                                helperText={errors.password_old}
                                onBlur={handleBlur}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="新密码"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                defaultValue={state.password}
                                onChange={handleChange}
                                error={errors.password ? true : false}
                                helperText={errors.password}
                                onBlur={handleBlur}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password_cf"
                                label="新密码确认"
                                type="password"
                                id="password_cf"
                                autoComplete="current-password"
                                defaultValue={state.password_cf}
                                onChange={handleChange}
                                error={errors.password_cf ? true : ((state.password_cf !== state.password) ? true : false)}
                                helperText={errors.password_cf}
                                onBlur={handleBlur}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                修  改
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/resetpassword" variant="body2">
                                        忘记旧密码?
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                </Container>
            </Paper>
        </Box>
    </ThemeProvider>)
};

