// import './SerivePage.css'
import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Button, Typography, TextField, IconButton } from '@mui/material';
import { Box, maxWidth, width } from '@mui/system';
import { FormatAlignJustify, UploadFileSharp } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from '../public/AlertDialog';
import { Paper } from '@mui/material';

const theme = createTheme();

function SerivePage({authvalue}) {
  const [error, setError] = useState(null);

  const [supTitle, setSupTitle] = useState(null);
  const [supInfomation, setSupInfomation] = useState('');
  const [uploadFile, setUploadFile] = React.useState([]);

  const [alert, setAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState('');

  const [sbButtonDis, setSbButtonDis] = useState(false);

  function supportAdd(data) {
    const requestOptions = {
      method: 'post',

      headers: {
        //"Content-Type":"multipart/form-data; boundary=abcd",
        'authorization': sessionStorage.getItem('token'),
      },

      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json;charset=utf-8',
      //   // 'authorization': sessionStorage.getItem('token'),
      // },
      body: data
    };

    fetch(process.env.REACT_APP_BASEURL + "/api/support/add", requestOptions)
      .then(res => {
        setSbButtonDis(false);
        console.log("not error")
        if (res.status !== 200) {
          {
            console.log("not 200 error")
            setAlertInfo('提交失败');
            setAlert(true);
            sessionStorage.clear();
          }
        } else {
          res.json().then(data => {
            console.log(data)
            setAlertInfo('提交成功');
            setAlert(true);

          })
        }
      }, error => {
        console.log(error)
        setAlertInfo('提交错误');
        setAlert(true);
        sessionStorage.clear();
        setError(error)
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSbButtonDis(true);
    const formData = new FormData();
    formData.append('title', supTitle);
    formData.append('detail', supInfomation);

    for (let i = 0; i < uploadFile.length; i++) {
      formData.append('file' + i, uploadFile[i]);
    }
    supportAdd(formData);
  };

  const handleuUploadFile = (e) => {
    console.log('e.target.files.length' + e.target.files.length);

    if (e.target.files.length) {
      const file = e.target.files[0];
      setUploadFile([...uploadFile, file]);
      e.target.value = '';
    }
  };

  const removeFile = (item) => {
    let index = uploadFile.indexOf(item);
    if (index < 0) return;
    console.log(uploadFile.length);
    setUploadFile(uploadFile.filter((count, indexTem) => indexTem !== index));
  };

  // if (error) {
  //   // return <div>Error: {error.message}</div>;
  //   return <div>发生错误啦！请尝试重新登陆...</div>
  // } else if (!isLoaded) {
  //   return <div>Loading...</div>;
  // } else {
  if (authvalue==-1) {
    return <div>Loading...</div>;
  } else {
  return (
    <ThemeProvider theme={theme}>
      {/* <Container component="main" maxWidth="xs" > */}
      <AlertDialog
        alertstate={alert}
        handlechange={() => {
          setAlert(false);
          // let isOk = () => {
          //   setIsSubmit(true);
          //   setShowLoginCallback();
          // }
          // authenticate() ? isOk() : setIsSubmit(false);
        }}
        alertinfo={alertInfo}
        isOnClose={false}
      />
      <div>
        <Box display='flex' sx={{ minHeight: '89vh', width: '100vw', justifyContent: 'center' }} alignItems='center'>
          <Paper elevation='3'>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5, mb: 5, ml: 5, mr: 5 }} >
              <Typography variant='h6'>
                使用建议OR软件错误
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <TextField label='标题' fullWidth margin="normal"
                  required
                  error={supTitle ? ((supTitle.length > 2) ? false : true) : false}
                  onChange={(e) => { setSupTitle(e.target.value); }}
                  helperText={supTitle ? ((supTitle.length > 2) ? '' : '输入过短') : ''}
                />
                <TextField
                  id="outlined-multiline-static"
                  label="内容"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  defaultValue=""
                  onChange={(e) => setSupInfomation(e.target.value)}
                />
                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-end'>
                  <Button variant="text" component="label" size='small'>选择文件
                    <input hidden accept="image/*,video/*,.zip,.rar,.txt,.doc,.docx,.pdf" multiple type="file" onChange={handleuUploadFile} />
                  </Button>
                </Box>
                {uploadFile.map((item) => (
                  <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' key={uploadFile.indexOf(item)}>
                    <Typography variant='body2' > 文件名:{item.name} {'\u00A0'} 大小:{parseInt(item.size / 1024)}kb</Typography>
                    <IconButton aria-label="delete" onClick={() => removeFile(item)} > <DeleteIcon /></IconButton>
                  </Box>
                ))}
                <Button
                  fullWidth
                  type='submit'
                  variant="contained"
                  disabled={sbButtonDis}
                  sx={{ mt: 3 }}>提交</Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </div>
      {/* </Container> */}
    </ThemeProvider>)
  }
}

export default SerivePage;
