import './AboutPage.css'
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Box} from '@mui/material';
import {useParams ,useLocation} from 'react-router-dom';
import queryToStr from 'query-string';
//sx={{ height: '89vh', width: '100vw' }} 
function AboutPage() {
    return (
	     <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ minHeight: '89vh' }}>
                <div className="display-flex-about">
                    <h3>LEIMODE</h3>
                    <hr />
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;据公安部统计,就2011年上半年全国共发生道路交通事故1840998起,同比增加18502起,直接财产损失4.4亿元，间接损失更无法估量。交通事故每天都在发生造成道路拥堵、困扰着人们的出行，
                        如何能够及时、准确、快速地处理好交通事故现场，保证道路的畅通是交通事故现场处理的关键所在。运用高科技手段来提高交通事故现场的处理效率，并对交通事故案例进行电子化管理也是交警部门的迫切需求。<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        为解决交通警察在交通事故现场勘查时手工测量和手工绘制比例图的现状，交通事故现场绘图系统充分利用计算机的图形处理技术，通过软硬件的综合应用，发挥计算机信息处理的优势，形成一套适用于交警在事故现场绘制
                        现场比例图的专业产品，实现“交通事故现场比例图”的快速绘制以及交通事故现场数据的数字化管理，并能方便与交通事故业务处理和交通违法业务处理等系统连接，为现场交通事故勘查提供快速和准确的手段，
                        使道路交通事故的处理更加高效、科学、规范、便捷。
                    </p>
                    <br/>
                    <p><small>微信:<img src='weichat.jpg' width='150px' /></small></p>
                    {/* <p><small>主页:www.leimode.cn</small></p> */}
                    <p><small>EMAIL:syleimode@163.com</small></p>
                    {/* <p><small>QQ:45607148</small></p> */}
                </div>
            </Box>
        )
}

export default AboutPage;
