import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AlertDialog from '../public/AlertDialog';
import useForm from "../public/useForm";
import { validator } from "../public/Validator";
import { Navigate } from "react-router-dom";
import { Paper } from '@mui/material';
// import { useCookies } from 'react-cookie';

const theme = createTheme();

export default function ModifyPassword() {
    const [alert, setAlert] = React.useState(false);
    const [alertInfo, setAlertInfo] = React.useState("");

    const [isSend, setIsSend] = React.useState(false);

    const [isSubmit, setIsSubmit] = React.useState(false);
    const [isGoBack, setIsGoBack] = React.useState(false);

    // const location = useLocation();
    // const result = queryToStr.parse(location.search);

    const initState = {
        email: sessionStorage.getItem('userName'),
        verifycode: "",
    };

    async function CheckPermise(data) {
        const requestOptions = {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'authorization': sessionStorage.getItem('token'),
            },
            body: JSON.stringify(data)
        };

        await fetch(process.env.REACT_APP_BASEURL+"/api/users/resetpassword", requestOptions)
            .then(res => {
                if (res.status !== 200) {
                    {
                        console.log("not 200 error")
                    }
                } else {
                    res.json().then(repData => {
                        console.log(repData);
                        setAlertInfo(repData.message);
                        setAlert(true);
                        if (repData.code == '200') {
                            console.log('重置密码成功！！！');
                            // Navigate({to:'/'});
                            setIsSubmit(true);
                        }
                        //state.name='';
                    })
                }
            }, error => {
                console.log(error)
            })
    }

    const submit = () => {
        console.log("Submited");
        var postData = { email: state.email, code: state.verifycode };
        CheckPermise(postData);
    };

    const handleSendVerificationCode = (e) => {
        console.log('handleSendVerificationCode');
        if (state.email === "") {
            return;
        }
        if (errors.email) {
            return;
        }

        const requestOptions = {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
            },
        };
        setIsSend(true);
        fetch(process.env.REACT_APP_BASEURL+"/api/users/getverificationcode?email=" + state.email, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    // setIsLoaded(true);
                    // setData(result);
                    setAlert(true);
                    setAlertInfo(result.message);
                    if (result.code === '200') {
                        setIsSend(true);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //sessionStorage.clear();
                    setAlert(true);
                    setAlertInfo(error);
                    setIsSend(false);
                }
            )
    }

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        state,
        errors,
        countryCode
    } = useForm({
        initState,
        callback: submit,
        validator
    });

    return (<ThemeProvider theme={theme}>
        <AlertDialog
            alertstate={alert}
            handlechange={() => {
                setAlert(false);
                if (isSubmit) {
                    setIsGoBack(true);
                }
                // let isOk = () => {
                //     setIsSubmit(true);
                //     // setShowLoginCallback();
                // }
                // authenticate() ? isOk() : setIsSubmit(false);
            }}
            alertinfo={alertInfo}
            isOnClose={false}
        />

        {isGoBack && (<Navigate to='/' />)}
        <Box display='flex' sx={{ height: '89vh', width: '100vw' }} alignItems='center' justifyContent='center'>
            <Paper  elevation='3'>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        // marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt:5,
                        mb:5,
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        重置密码
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                        <Box display='flex' flexDirection='row' width='400px' mb={1} alignItems='center'>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="邮箱地址"
                                name="email"
                                autoComplete="email"
                                defaultValue={state.email}
                                onChange={handleChange}
                                error={errors.email ? true : false}
                                helperText={errors.email}
                                onBlur={handleBlur}
                            />
                            <Button variant="outlined" size="medium" sx={{ ml: 1 }}
                                disabled={isSend || errors.email || state.email === ""} onClick={handleSendVerificationCode}>
                                发送
                            </Button>
                        </Box>

                        <TextField
                            name="verifycode"
                            required
                            fullWidth
                            id="verifycode"
                            label="验证码"
                            defaultValue={state.verifycode}
                            onChange={handleChange}
                            error={errors.verifycode ? true : false}
                            helperText={errors.verifycode}
                            onBlur={handleBlur}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            确  定
                        </Button>
                    </Box>
                </Box>
                {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
            </Container>
            </Paper>
        </Box>
    </ThemeProvider>)
};

