import "./FootPage.css"
import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Typography, Link } from '@mui/material/';
import { Box } from "@mui/system";

function FootPage() {
    // return (<div className="display-flex_footpage">
    //    2022 CopyRight 沈阳雷盟科技有限公司
    // </div>);
    // const [offSet, setOffSet] = useState(0);

    // const offSetFootPage = () => {
    //     const height=document.querySelector('body').offsetHeight;
    //     const winHeight=window.innerHeight;
    //     console.log('body height:'+height);
    //     console.log('windows height',window.innerHeight);
    //     const lenght=winHeight-height;
    //     console.log('lenght:'+lenght);
    //     if(lenght>0)
    //     {
    //         setOffSet(lenght);
    //     }
    // };

    //useEffect(offSetFootPage,[]);

    return (<Box>
        <AppBar position="static" id="footPage" >
        <Box height='4vh' display='flex' flexDirection='row' alignItems='center' justifyContent='center' sx={{display: {xs: 'none', md: 'flex' }}}>
            <Typography variant='button'>
                © 2013-2024 LEIMODE
            </Typography>
            <Link href='http://beian.miit.gov.cn/' variant='button' underline='none' color="inherit" target="_blank" ml={2}>
                辽ICP备2022010706号-1
            </Link>
        </Box>

        <Box height='6vh' display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{display: {xs: 'flex', md: 'none' }}}>
            <Typography variant='button'>
                © 2013-2022 沈阳雷盟科技有限公司版权所有
            </Typography>
            <Link href='http://beian.miit.gov.cn/' variant='button' underline='none' color="inherit" target="_blank">
                辽ICP备2022010706号-1
            </Link>
        </Box>
    </AppBar>
    </Box >);
}

export default FootPage;

