import { parsePhoneNumber } from "react-phone-number-input";
import { get } from "lodash";

// ******************************
export const validator = (values, fieldName) => {
    let errors = {};
    switch (fieldName) {
        case "name":
            validateName(values.name, errors);
            break;
        case "company":
            vaildateCompany(values.company, errors);
            break;
        case "email":
            validateEmail(values.email, errors);
            break;
        case "phone":
            validatePhoneNumber(values.phone, errors);
            break;
        case "password":
            validatePassword(values.password, errors);
            break;
        case "password_cf":
            validatePassword(values.password_cf, errors);
            break;
        case "password_old":
            validatePassword(values.password_old, errors);
            break;
        case "verifycode":
            validateVerifyCode(values.verifycode, errors);
            break;
        default:
    }
    return errors;
};

// ******************************
export function getCountryCode(phoneNumber) {
    return get(parsePhoneNumber(phoneNumber), "country");
}

function validateName(name, errors) {
    if (!name) {
        errors.name = "必须填写姓名";
        return false;
    }

    if (name.length < 2 || name.length > 20) {
        console.log('name' + name.length);
        errors.name = "请正确填写姓名";
        return false;
    }
    console.log(name + name.length);
    return true;
}

function vaildateCompany(company, errors) {
    if (!company) {
        errors.company = "必须填写单位名称";
        return false;
    }
    if (company.length < 2 || company.length > 20) {
        errors.company = "请正确填写单位名称";
        return false;
    }
    return true;
}

// ******************************
function validatePhoneNumber(phone, errors) {
    let result = true;
    const phoneObject = parsePhoneNumber('+86' + phone);
    if (!phoneObject) {
        errors.phone = "无效电话号码";
        result = false;
    }
    return result;
}
// ******************************
function validateEmail(email, errors) {
    let result = true;

    if (!email) {
        errors.email = "必须填写邮箱地址";
        result = false;
    } else {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = re.test(String(email).toLowerCase());
        if (!result) errors.email = "无效邮箱地址";
    }
    return result;
}
// ******************************
function validatePassword(pass, errors) {
    let result = true;

    if (!pass) {
        errors.password = "必须填写密码";
        result = false;
    } else {
        var lower = /(?=.*[a-z])/;
        result = lower.test(pass);

        if (!result) {
            errors.password = "密码必须包含一个小写字母";
            result = false;
        } else if (pass.length < 8) {
            errors.password = "密码必须8位及以上";
            result = false;
        }
    }
    return result;
}

function validateVerifyCode(name, errors) {
    if (!name) {
        errors.name = "必须填写验证码";
        return false;
    }

    if (name.length !=6) {
        console.log('name' + name.length);
        errors.name = "请正确填写验证码";
        return false;
    }
    console.log(name + name.length);
    return true;
}