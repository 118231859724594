import './MainTitle.css'
import React from 'react';
import Link from '@mui/material/Link';
import LoginDialog from './UserManagement/LoginDialog';
import RegisterDialog from './UserManagement/RegiterDialog'
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import AlertDialog from './public/AlertDialog';
import { useCookies } from 'react-cookie';


function MainTitle({ showLogin, setShowLoginCallback }) {
  const [alert, setAlert] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['name','token'])
  // function checkLogin(){
  //   const userInfo=localStorage.getItem('userInfo');
  //   if(userInfo){
  //     try{
  //       return JSON.parse(userInfo);
  //     }catch(e)
  //     {
  //       console.error(e);
  //     }
  //   }
  // }


  // let nav = useNavigate();

  // let jsString=checkLogin();
  // console.log(jsString);
  const loginOut = () => {
    setAlert(true);
  }

  return (
    <div className="display-flex">
      <AlertDialog
        alertstate={alert}
        handlechange={() => {
          setAlert(false);
          sessionStorage.clear();

          removeCookie('name', { path: '/' });
          removeCookie('token', { path: '/' });

          setShowLoginCallback();
        }}
        handleCancel={() => {
          setAlert(false);
        }}
        alertinfo="是否注销登陆"
        isOnClose={false}
      />

      <div>
        <Link href="/index.jsp" underline="none">
          <div className="display-flex0">
            <img src="leimode.png" className="Leimode-logo" alt="logo" />
            <Typography variant="h6" gutterBottom>
              沈阳雷盟科技有限公司
            </Typography>
          </div>
        </Link>
      </div>
      <div className="display-flex2" />
      <div className="display-flex2" />
      <div className="display-flex1">

        <div>
          {/* <Link href="/produce" underline="none">产品</Link> */}
          <Button href='/produce' size="large">产品</Button>
        </div>

        <div>
          {/* <Link href="/serive" underline="none">服务支持</Link> */}
          <Button href='/serive' size="large">支持</Button>
        </div>

        <div>
          {/* <Link href="/download" underline="none">下载</Link> */}
          <Button href='/download' size="large">下载</Button>
        </div>

        <div>
          {/* <Link href="/about" underline="none">关于我们</Link> */}
          <Button href='/about' size="large">关于我们</Button>
        </div>
      </div>

      <div className="display-flex2" />
      <div className="display-flex2" />
      <div className="display-flex2">
        <div style={{ display: showLogin ? 'none' : 'flex' }}>
          {/* <Link href="/register" underline="none">注册</Link> */}
          {/* <RegisterDialog/> */}
          <RegisterDialog />
        </div>
        <div style={{ display: showLogin ? 'none' : 'flex' }}>
          {/* <Link href="/login" underline="none">登陆</Link> */}
          <LoginDialog setShowLoginCallback={setShowLoginCallback} />
        </div>
        <div className="display-flex2" style={{ display: showLogin ? 'flex' : 'none' }}>
          <Button onClick={loginOut}>{sessionStorage.getItem('userName')}</Button>
        </div>
      </div>
    </div>
  )
}

export default MainTitle;
