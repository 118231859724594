import './ProducePage.css'
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


function ProducePage() {
  const infoArray = [
    [{
      title: 'IOS道路交通事故现场绘图',
      info: '快速准确的绘制交通事故现场图的比例图,支持APPLE的IPAD系列产品,生成事故现场勘察笔录等文书。',
      image: 'produce_0.jpg'
    },
    {
      title: 'IOS道路交通事故现场绘图无人机',
      info: '快速准确的勘查道路交通事故现场,支持主流无人机,支持APPLE的IPAD系列产品。',
      image: 'produce_1.jpg'
    },
    {
      title: 'ANDROID道路交通事故现场绘图',
      info: '快速准确的绘制交通事故现场图的比例图,支持主流ANDROID设备,生成事故现场勘察笔录等文书。',
      image: 'produce_2.jpg'
    }],
    [{
      title: 'ANDROID道路交通事故现场绘图无人机',
      info: '快速准确的勘查道路交通事故现场,支持主流无人机,支持主流ANDROID设备。',
      image: 'produce_3.jpg'
    },
    {
      title: 'WINDOWS道路交通事故现场绘图',
      info: '快速准确的绘制交通事故现场图的比例图,采用全PC电脑绘制,生成事故现场勘察笔录等文书。',
      image: 'produce_4.jpg'
    },
    {
      title: 'WINDOWS道路交通事故现场三维模拟',
      info: '快速完成交通事故现场场景、车辆、人物的再现,并生成视频文件。可以逼真的模拟交通事故过程。',
      image: 'produce_5.jpg'
    }],
    [{
      title: '道路交通事故现场勘查仪器',
      info: '一体化设计，防水、防震、防尘、工业级产品设计，对使用环境要求低、简单易用。使勘查交通事故现场变得准确、快速、轻松、便捷，提高交通事故现场处理效率、快速拆除事故现场。',
      image: 'produce_6.jpg'
    },
    {
      title: '道路交通事故全景勘查系统',
      info: '快速准确的采集交通事故现场3D信息,按要求生成比例图，自动生成事故现场勘察笔录。',
      image: 'produce_7.jpg'
    },
    {
      title: '道路交通事故黑点统计分析系统',
      info: '具有对交通事故的统计分析功能，可以了解交通事故的总体分布状况、发展动向及各种影响因素对交通事故的作用和相互影响，为交通事故的预测和安全评价提供理论依据。',
      image: 'produce_8.jpg'
    }]
  ];

  return (
    <Box>
      <Box display='flex' flexDirection='column' sx={{ display: { xs: 'none', md: 'flex' },mb: 4 }} justifyContent='center'>
        {
          infoArray.map((item) => (<Box key={infoArray.indexOf(item)} display='flex' justifyContent='center'>
            {item.map((item_item) => (<Card key={item.indexOf(item_item)} sx={{ maxWidth: 345, mt: 4, ml: item.indexOf(item_item) ? 4 : 0 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="240"
                  //display: { xs: 'flex', md: 'none' }, 
                  image={item_item.image}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div">
                    {item_item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" textAlign='left'>
                    {item_item.info}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>))}
          </Box>))
        }
      </Box>

      <Box display='flex' flexDirection='column' sx={{ display: { xs: 'flex', md: 'none' },mb: 4 }} justifyContent='center'>
        {
          infoArray.map((item) => (<Box key={item.indexOf()} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            {item.map((item_item) => (<Card key={item_item.title} display='flex' flexDirection='column' sx={{ maxWidth: 345, mt: 4 }} justifyContent='center'>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="240"
                  image={item_item.image}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div">
                    {item_item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" textAlign='left'>
                    {item_item.info}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>))}
          </Box>))
        }
      </Box>
    </Box>
  );
};

export default ProducePage;