import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import RegisterPage from './RegisterPage'

export default function RegisterDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Link onClick={handleClickOpen} href='#' underline="none">注册</Link> */}
      <Button onClick={handleClickOpen} size="large">注册</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
            <RegisterPage handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}