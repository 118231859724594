import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography } from '@mui/material';
import { useLocation, us } from 'react-router-dom';
import queryToStr from 'query-string';

var isLoad=false;

function Activate() {
    const [error, setError] = useState(null);
    const [isActivate, setIsActivate] = useState(false);

    const location = useLocation();
    const result = queryToStr.parse(location.search);

    console.log(result);

    

    const activateId = () => {
        if(isLoad)
        {
            return;
        }
        isLoad=true;

        const requestOptions = {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                // 'authorization': sessionStorage.getItem('token'),
            },
            // body: JSON.stringify(data)
        };

        fetch(process.env.REACT_APP_BASEURL+"/api/users/activate?id=" + result.id, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.code === '200') {
                        setIsActivate(true);
                    }
                    else {
                        setIsActivate(false);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsActivate(false);
                    setError(error);
                }
            )
            console.log('activateId');
            var height=document.getElementById('footPage').clientHeight;
            console.log(height);
            height=document.getElementById('appBar').clientHeight;
            console.log(height);
            height=document.getElementById('activate').clientHeight;
            console.log(height);
    };
    
    useEffect(activateId, []);
    //<Box display='flex'  alignItems='center' justifyContent='center'>
    return (< Box  id='activate'  display='flex' justifyContent='center' alignItems='center' sx={{ height: '89vh', width: '100vw' }}>
        <Typography variant='h6'>{error ? '发生错误！！' : isActivate ? '账户激活成功,需等待分配权限后方可登录！！' : '账户激活失败！！'}</Typography>
    </Box >);
    // }
};

export default Activate;