import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SignIn from './LoginPage';
import AlertDialog from '../public/AlertDialog';

export default function LoginDialog({setShowLoginCallback}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowLoginCallback();
  };

  // const alertCall = (alert) => {
    
  // }

  return (
    <div>
      {/* <Link onClick={handleClickOpen} href='#' underline="none">登陆</Link> */}

      <Button onClick={handleClickOpen} size="large">登陆</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
            <SignIn setShowLoginCallback={handleClose}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}