import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/AppRegistration';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useForm from "../public/useForm";
import { validator } from "../public/Validator";
import AlertDialog from '../public/AlertDialog';
import { Paper } from '@mui/material';
// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const theme = createTheme();

export default function SignUp({ handleClose }) {
  const [openAlert, setOpenAlert,] = React.useState(false);
  const [openAlertInfo, setOpenAlertInfo] = React.useState("");

  const [isSignUp, setIsSignUp] = React.useState(false);

  function postInfo(data) {
    const requestOptions = {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data)
    };

    fetch(process.env.REACT_APP_BASEURL+"/api/users/add", requestOptions)
      .then(res => {
        if (res.status !== 200) {
          {
            console.log("not 200 error")
          }
        } else {
          res.json().then(data => {
            if (data.code === '200') {
              //isSignUp=true;
              setIsSignUp(true);
            }
            console.log(data);
            setOpenAlertInfo(data.message);
            setOpenAlert(true);
          })
        }
      }, error => {
        console.log(error)
      })
  }


  const initState = {
    name: "",
    company: "",
    email: "",
    phone: "",
    password: "",
    password_cf: "",
  };

  const submit = () => {
    if (state.password !== state.password_cf) {
      setOpenAlert(true);
      setOpenAlertInfo('两次输入的密码不一致');
      errors.password = 'error';
      errors.password_cf = 'error';
      return;
    }
    var userData = { name: state.name, password: state.password, company: state.company, email: state.email.toLowerCase(), phone: state.phone };
    postInfo(userData)
    console.log("Submited");
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    countryCode
  } = useForm({
    initState,
    callback: submit,
    validator
  });

  //let isValidForm =Object.values(errors).filter(error => typeof error !== "undefined").length === 0;

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);

  //   // var object={};
  //   // data.forEach((value,key)=>object[key]=value);
  //   var userData = { name: data.get('name'), password: data.get('password'), company: data.get('company'), email: data.get('email'), phone: data.get('phone') };
  //   postInfo(userData)
  //   Array.from(event.target).forEach((e) => (e.value = ""));
  // };

  return (
    <ThemeProvider theme={theme}>
      <AlertDialog
        alertstate={openAlert}
        handlechange={() => {
          setOpenAlert(false)
          if (isSignUp) {
            handleClose();
          }
        }}
        alertinfo={openAlertInfo}
      />
      <Box display='flex' sx={{ minHeight: '89vh', width: '100vw' }} alignItems='center' justifyContent='center'>
        {/* <Paper elevation='3'> */}
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt:2,
                mb:2,
              }}
            >
              <Avatar sx={{ bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                注 册
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="姓名"
                      defaultValue={state.name}
                      onChange={handleChange}
                      error={errors.name ? true : false}
                      helperText={errors.name}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="company"
                      label="单位"
                      name="company"
                      autoComplete="company"
                      defaultValue={state.company}
                      onChange={handleChange}
                      error={errors.company ? true : false}
                      helperText={errors.company}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="email"
                      label="邮箱地址"
                      name="email"
                      autoComplete="email"
                      defaultValue={state.email}
                      onChange={handleChange}
                      error={errors.email ? true : false}
                      helperText={errors.email}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      label="联系电话"
                      name="phone"
                      autoComplete="phone"
                      defaultValue={state.phone}
                      onChange={handleChange}
                      error={errors.phone ? true : false}
                      helperText={errors.phone}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="密码"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      defaultValue={state.password}
                      onChange={handleChange}
                      error={errors.password ? true : false}
                      helperText={errors.password}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password_cf"
                      label="密码确认"
                      type="password"
                      id="password_cf"
                      autoComplete="new-password"
                      defaultValue={state.password_cf}
                      onChange={handleChange}
                      error={errors.password_cf ? true : ((state.password_cf !== state.password) ? true : false)}
                      helperText={errors.password_cf}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  注 册
                </Button>

                <Grid container justifyContent="center">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      已有账户，转到登陆
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Container>
        {/* </Paper> */}
      </Box>
    </ThemeProvider>
  );
}