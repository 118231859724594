import * as React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button,Box } from '@mui/material';
// import { } from '@mui/system';

const AlertDialog = ({
    alertstate,
    handlechange,
    handleCancel,
    alertinfo,
    isOnClose
}) => {
    return <Dialog
        onClose={(isOnClose === true) ? handlechange : null}
        open={alertstate}
        maxWidth='xs'
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    >
        <DialogTitle>提示</DialogTitle>
        <DialogContent>
            <DialogContentText>{alertinfo}</DialogContentText>
        </DialogContent>
        <Box sx={{display:'flex'}}>
            <Button onClick={handlechange} autoFocus={true} sx={{width:handleCancel?'50%':'100%'}}>确定</Button>
            <Button onClick={handleCancel}  sx={{width:'50%',display:handleCancel?'flex':'none'} } >取消</Button>
        </Box>
    </Dialog>
}

export default AlertDialog;