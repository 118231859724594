import React from 'react';
import { Navigate } from "react-router-dom";

export const authenticate = (callback) => {
  let token = sessionStorage.getItem('token');
  console.log('token=' + token);
  console.log(token);

  if (token == null) {
    callback(0);
    console.log('callback = 0');
    return;
  }

  const requestOptions = {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    },
  };
  //var isAuth=false;
  fetch(process.env.REACT_APP_BASEURL + "/api/users/authenticate?id=" + token, requestOptions)
    .then(res => res.json())
    .then((result) => {
      //isAuth=true;
      console.log('result.message=' + result.message);
      callback(parseInt(result.message));
      console.log('callback = '+result.message);
    },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        callback(0);
        console.log('callback = 0 and clear');
        sessionStorage.clear();
      }
    )

  //let response = await fetch(process.env.REACT_APP_BASEURL+"/api/users/authenticate?id="+sessionStorage.getItem('token'))

  //return token ? 2 : 0;
}

const PrivateRoute = ({ children, authvalue }) => {
  console.log('authvalue=' + authvalue);
  return authvalue ? children : <Navigate to="/login" />

  // let retu=0;
  // authenticate((auth)=>{
  //     console.log('auth='+auth);
  //     retu=auth;
  //     //return auth ? children : <Navigate to="/login" />
  //     //return <Navigate to="/login" />;
  // });
  // console.log('retu='+retu);
  // return retu ? children : <Navigate to="/login" />
}

export default PrivateRoute;