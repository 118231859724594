import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Typography, TextField, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import ModifyDeviceDialog from './ModifyDeviceDialog';
import AlertDialog from '../public/AlertDialog';

const columns = [
    {
        id: 'device_udid',
        label: 'UDID',
        minWidth: 190,
        align: 'center',
    },
    {
        id: 'product_type',
        label: '产品类型',
        minWidth: 20,
        align: 'center',
    },
    // {
    //     id: 'device_serial',
    //     label: '序列号',
    //     minWidth: 50,
    //     align: 'center',
    //     // format: (value) => value.toFixed(2),
    // },
    {
        id: 'device_type',
        label: '型号',
        minWidth: 20,
        align: 'center',
        //format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'device_version',
        label: '版本',
        minWidth: 20,
        align: 'center',
        //format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'create_time',
        label: '创建时间',
        minWidth: 40,
        align: 'center',
        format: (value) => new Date(value).toLocaleString(),
    },
    {
        id: 'encryption_time',
        label: '激活时间',
        minWidth: 40,
        align: 'center',
        format: (value) => value ? new Date(value).toLocaleString() : value,
    },
    {
        id: 'product_item_type',
        label: '软件型号',
        minWidth: 20,
        align: 'center',
        //format: (value) => value.toFixed(2),
    },
    {
        id: 'dealer_name',
        label: '代理商',
        minWidth: 170,
        align: 'center',
        //format: (value) => value.toFixed(2),
    },
    // {
    //     id: 'remark_info',
    //     label: '备注',
    //     minWidth: 100,
    //     align: 'center',
    //     format: (value) => value.toFixed(2),
    // },
    // {
    //     id: 'activated',
    //     label: '是否激活',
    //     minWidth: 70,
    //     align: 'center',
    //     format: (value) => value.toFixed(2),
    // },
    {
        id: 'device_locked',
        label: '锁定',
        minWidth: 30,
        align: 'center',
        format: (value) => value ? '是' : '否',
    }
];


function createData(device_udid, product_type, device_serial, device_type, device_version, create_time, encryption_time,
    product_item_type, dealer_name, remark_info, device_locked, product_serial, product_key, operation_count) {

    return {
        device_udid, product_type, device_serial, device_type, device_version, create_time, encryption_time,
        product_item_type, dealer_name, remark_info, device_locked, product_serial, product_key, operation_count
    };
}

const productTypes = [
    {
        value: 'IOS_HANDYDRAW',
        label: 'IOS_HANDYDRAW',
    },
    {
        value: 'IOS_HANDYCAPTURE',
        label: 'IOS_HANDYCAPTURE',
    },
    {
        value: 'ANDROID_HANDYDRAW',
        label: 'ANDROID_HANDYDRAW',
    },
    {
	value: 'ANDROID_HANDYVISION',
	label: 'ANDROID_HANDYVISION',
    },
    {
        value: 'WINDOWS_HANDYDRAW',
        label: 'WINDOWS_HANDYDRAW',
    },
    {
        value: '',
        label: '清 空',
    },
];

export default function DeviceManagement() {
    const theme = useTheme();
    const [locale, setLocale] = React.useState('zhCN');
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);

    const [alert, setAlert] = React.useState(false);
    const [alertInfo, setAlertInfo] = React.useState("");

    const [productTypeValue, setProductTypeValue] = React.useState('');
    const [udidValue, setUdidValue] = React.useState('');
    const [deviceSerialValue, setDeviceSerialValue] = React.useState('');
    const [dealerNameValue, setDealerNameValue] = React.useState('');
    const [operationCountValue, setOperationCountValue] = React.useState('');

    const [modifyDeviceDialog, setModifyDeviceDialog] = React.useState(false);
    const [deviceInfo, setDeviceInfo] = React.useState({});
    const [productItemType, setProductItemType] = useState('');
    const [deviceLocked, setDeviceLocked] = useState(false);
    const [dealerName, setDealerName] = useState('');
    const [remarkInfo, setRemarkInfo] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const updateList = (param) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
            },
        };

        fetch(process.env.REACT_APP_BASEURL + "/api/handydraw/list?id=" + sessionStorage.getItem('token') + param, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.data == null) {
                        return;
                    }
                    let rows_tem = [];
                    for (let i = 0; i < result.data.length; i++) {
                        rows_tem.push(createData(result.data[i].device_udid, result.data[i].product_type,
                            result.data[i].device_serial, result.data[i].device_type, result.data[i].device_version,
                            result.data[i].create_time, result.data[i].encryption_time, result.data[i].product_item_type,
                            result.data[i].dealer_name, result.data[i].remark_info, result.data[i].device_locked == 1 ? true : false,
                            result.data[i].product_serial, result.data[i].product_key, result.data[i].operation_count));
                    }

                    setRows(rows_tem);
                },
                (error) => {
                    sessionStorage.clear();
                }
            )
    }

   // useEffect(() => { updateList('') }, []);

    const handleChange = (event) => {
        console.log(event.target.value);
        setProductTypeValue(event.target.value);
    };


    const handleClick = (event) => {
        setPage(0);
        let param = '&device_udid=' + udidValue + '&product_type=' + productTypeValue + '&device_serial=' +
            deviceSerialValue + '&dealer_name=' + dealerNameValue + '&operation_count=' + operationCountValue;
        updateList(param);
    };

    const updateModify = (callback) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'authorization': sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
                udid: deviceInfo.device_udid, productType: deviceInfo.product_type,
                productItemType: productItemType, deviceLocked: deviceLocked,
                dealerName: dealerName, remarkInfo: remarkInfo
            })
        };
        fetch(process.env.REACT_APP_BASEURL + '/api/handydraw/modifyHandydrawInfo', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.code === '200') {
                        callback();
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    return (
        <Box display='flex' sx={{ minHeight: '89vh', justifyContent: 'center', alignItems: 'center' }} >
            <ThemeProvider theme={themeWithLocale}>
                <AlertDialog
                    alertstate={alert}
                    handlechange={() => {
                        setAlert(false);
                        setModifyDeviceDialog(false);

                        console.log('deviceInfo:');
                        //console.log(devInfo);
                        console.log(productItemType);

                        updateModify(() => {
                            console.log('updateModify');
                            deviceInfo.product_item_type = productItemType;
                            deviceInfo.device_locked = deviceLocked;
                            deviceInfo.dealer_name = dealerName;
                            deviceInfo.remark_info = remarkInfo;
                            setDeviceInfo({ ...deviceInfo });
                        })
                    }}
                    handleCancel={() => {
                        setAlert(false);
                    }}
                    alertinfo={alertInfo}
                    isOnClose={false}
                />

                <ModifyDeviceDialog
                    alertstate={modifyDeviceDialog}
                    productItemType={productItemType}
                    setProductItemType={setProductItemType}
                    deviceLocked={deviceLocked}
                    setDeviceLocked={setDeviceLocked}
                    dealerName={dealerName}
                    setDealerName={setDealerName}
                    remarkInfo={remarkInfo}
                    setRemarkInfo={setRemarkInfo}
                    handlechange={(devInfo) => {
                        setAlert(true);
                        setAlertInfo('是否确定修改信息？');
                    }}
                    handleCancel={() => {
                        setModifyDeviceDialog(false);
                    }}

                    deviceInfoValue={Object.assign({}, deviceInfo)}
                />
                <Box>
                    {/* <Typography component="h1" variant="h5" mt={2}>
                    设 备 管 理
                </Typography> */}
                    <Box display='flex' sx={{ mt: 5, width: '80vw', justifyContent: 'center', alignItems: 'center' }}>
                        <TextField
                            label="UDID"
                            defaultValue=""
                            onChange={(event) => {
                                setUdidValue(event.target.value);
                            }}
                            sx={{ width: 400 }}
                            size="small"
                        />
                        <TextField
                            label="设备序列号"
                            defaultValue=""
                            onChange={(event) => {
                                setDeviceSerialValue(event.target.value);
                            }}
                            sx={{ width: 200, ml: 2 }}
                            size="small"
                        />

                        <TextField
                            select
                            label="产品类型"
                            value={productTypeValue}
                            onChange={handleChange}
                            sx={{ ml: 2, width: 270 }}
                            size="small"
                        >
                            {productTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="代理商"
                            defaultValue=""
                            onChange={(event) => {
                                setDealerNameValue(event.target.value);
                            }}
                            sx={{ width: 200, ml: 2 }}
                            size="small"
                        />

                        <TextField
                            label="次数"
                            defaultValue=""
                            onChange={(event) => {
                                setOperationCountValue(event.target.value);
                            }}
                            sx={{ minWidth: 70, maxWidth: 80, ml: 2 }}
                            size="small"
                        />

                        <Button variant="contained" sx={{ ml: 2 }} onClick={handleClick} >查询</Button>
                    </Box>

                    <Paper sx={{ width: '90vw', overflow: 'hidden', mt: 5 }}>
                        <TableContainer sx={{ minHeight: 640 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.productType + row.device_udid} style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        console.log(row.device_udid);
                                                        setModifyDeviceDialog(true);
                                                        setDeviceInfo(row);
                                                        setProductItemType(row.product_item_type);
                                                        setDeviceLocked(row.device_locked == 1 ? true : false);
                                                        setDealerName(row.dealer_name);
                                                        setRemarkInfo(row.remark_info);
                                                    }}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format ? column.format(value) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </ThemeProvider>
        </Box>
    );
}
