import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { Button, Typography, TextField, IconButton } from '@mui/material';
import Switch from '@mui/material/Switch';
import AlertDialog from '../public/AlertDialog';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';

const columns = [
    {
        id: 'email',
        label: '邮箱',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'name',
        label: '姓名',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 'company',
        label: '单位名称',
        minWidth: 170,
        align: 'center',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'phone',
        label: '联系电话',
        minWidth: 170,
        align: 'center',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'activated',
        label: '账户状态',
        minWidth: 170,
        align: 'center',
        format: (value) => value?'激活':'未激活',
    },
    {
        id: 'trialhandydoc',
        label: '开启试用',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'verified',
        label: '启用账户',
        minWidth: 170,
        align: 'center',
        // format: (value) => value.toFixed(2),
    }
];

function createData(email, name, company, phone, activated, trialhandydoc, verified) {
    //const density = population / size;
    return { email, name, company, phone, activated, trialhandydoc, verified };
}

// const rows = [
//     createData('guanslei@gmai.com', 'guanslei', 'leimode', 17702440699, false, true),
//     createData('China', 'CN', 1403500365, 9596961, true, false),
//     createData('Italy', 'IT', 60483973, 301340, false, false),
//     createData('United States', 'US', 327167434, 9833520, false, false),
//     createData('Canada', 'CA', 37602103, 9984670, false, false),
//     createData('Australia', 'AU', 25475400, 7692024, false, false),
//     createData('Germany', 'DE', 83019200, 357578, false, false),
//     createData('Ireland', 'IE', 4857000, 70273, false, false),
//     createData('Mexico', 'MX', 126577691, 1972550, true, false),
//     createData('Japan', 'JP', 126317000, 377973, false, false),
//     createData('France', 'FR', 67022000, 640679, false, false),
//     createData('United Kingdom', 'GB', 67545757, 242495, false, false),
//     createData('Russia', 'RU', 146793744, 17098246, false, false),
//     createData('Nigeria', 'NG', 200962417, 923768, false, false),
//     createData('Brazil', 'BR', 210147125, 8515767, false, false),
// ];

export default function UserManagement() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [rows, setRows] = React.useState([]);

    const [userInfo, setUserInfo] = React.useState({});

    const [alert, setAlert] = React.useState(false);
    const [alertInfo, setAlertInfo] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const updateList = () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
            },
            // body: JSON.stringify(data)
        };

        fetch(process.env.REACT_APP_BASEURL + "/api/users/list?id=" + sessionStorage.getItem('token'), requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.data == null) {
                        return;
                    }
                    let rows_tem = [];
                    for (let i = 0; i < result.data.length; i++) {
                        rows_tem.push(createData(result.data[i].email, result.data[i].name,
                            result.data[i].company, result.data[i].phone, result.data[i].activated == 1 ? true : false,
                            result.data[i].trial_handydocument == 1 ? true : false,result.data[i].verified == 1 ? true : false));
                    }

                    setRows(rows_tem);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                    sessionStorage.clear();
                }
            )
    };

    const updateVerified = (verified, callback) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'authorization': sessionStorage.getItem('token'),
            },
            body: JSON.stringify({ email: userInfo.email, value: verified })
        };
        fetch(process.env.REACT_APP_BASEURL + '/api/users/verify', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.code === '200') {
                        callback();
                    }
                },
                (error) => {
                    //setIsActivate(false);
                    //setError(error);
                    //sessionStorage.clear();
                }
	    )
    }

    const updateTrialHandydoc = (isOpen, callback) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'authorization': sessionStorage.getItem('token'),
            },
            body: JSON.stringify({ email: userInfo.email, value: isOpen })
        };
        fetch(process.env.REACT_APP_BASEURL + '/api/users/trialhandydocument', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.code === '200') {
                        callback();
                    }
                },
                (error) => {
                    //setIsActivate(false);
                    //setError(error);
                    //sessionStorage.clear();
                }
            )
    }

    useEffect(updateList, []);
    const theme = useTheme();
    const [locale, setLocale] = React.useState('zhCN');
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );
    return (
        <Box display='flex' sx={{ minHeight: '89vh', justifyContent: 'center', alignItems: 'center' }} >
            <ThemeProvider theme={themeWithLocale}>
                <AlertDialog
                    alertstate={alert}
                    handlechange={() => {
			if (alertInfo.includes('试用权限')) {
				let trialhandydoc = !userInfo.trialhandydoc;
				setAlert(false);
				updateTrialHandydoc(trialhandydoc, () => {
					userInfo.trialhandydoc = trialhandydoc;
					setUserInfo({ ...userInfo });
				});
			} 
			else{
				let verified = !userInfo.verified;
                                setAlert(false);
                                updateVerified(verified, () => {
                                        userInfo.verified = verified;
                                        setUserInfo({ ...userInfo });
                                });
			}}
		    }
                    handleCancel={() => {
                        setAlert(false);
                    }}
                    alertinfo={alertInfo}
                    isOnClose={false}
                />
                <Box>
                    {/* <Typography component="h1" variant="h5" mt={5}>
                    用 户 管 理
                </Typography> */}
                    <Paper sx={{ width: '100%', overflow: 'hidden', mt: 5 }}>
                        <TableContainer sx={{ maxHeight: 640, minHeight: 640 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                                                    {columns.map((column) => {
                                                        if (column.id == 'verified') {
                                                            return (<TableCell key={column.id} align={column.align}>
                                                                <Switch
                                                                    id={row['email']}
                                                                    checked={row.verified}
                                                                    disabled={row['email'] == 'guanslei@gmail.com' ? true : false}
                                                                    onChange={(event) => {
                                                                        setAlert(true);
                                                                        setUserInfo(row);
                                                                        if (userInfo.verified) {
                                                                            setAlertInfo('是否关闭 ' + row['email'] + ' 帐户');
                                                                        }
                                                                        else {
                                                                            setAlertInfo('是否启用 ' + row['email'] + ' 账户');
                                                                        }
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </TableCell>)
                                                        }
							if (column.id == 'trialhandydoc') {
                                                            return (<TableCell key={column.id} align={column.align}>
                                                                <Switch
                                                                    id={row['email']}
                                                                    checked={row.trialhandydoc}
                                                                    disabled={row['email'] == 'guanslei@gmail.com' ? true : false}
                                                                    onChange={(event) => {
                                                                        setAlert(true);
                                                                        setUserInfo(row);
                                                                        if (userInfo.verified) {
                                                                            setAlertInfo('是否关闭 ' + row['email'] + ' 试用权限');
                                                                        }
                                                                        else {
                                                                            setAlertInfo('是否启用 ' + row['email'] + ' 试用权限');
                                                                        }
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </TableCell>)
                                                        }
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'boolean'
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </ThemeProvider>
        </Box>
    );
}
