// import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import MainTitle from './MainTitle';
import MainPage from './MainPage';
import FootPage from './FootPage/FootPage';
import AboutPage from './AboutPage/AboutPage';
import ProducePage from './ProducePage/ProducePage';
import DownLoadPage from './DownloadPage/DownLoadPage';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import NewsPage from './NewsPage/NewsPage';
import SerivePage from './SerivePage/SerivePage';
import LoginPage from './UserManagement/LoginPage';
import RegisterPage from './UserManagement/RegisterPage';
import PrivateRoute, { authenticate } from './public/PrivateRouter';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react'
import ResponsiveAppBar from './AppTitle'
import ModifyPassword from './UserManagement/ModifyPassword';
import Activate from './UserManagement/Activate'
import ResetPassword from './UserManagement/ResetPassword'
import UserManagement from './UserManagement/UserManagement';
import DeviceManagement from './DeviceManagement/DeviceManagement';

function App() {
  const [isShowLogin, setIsShowLogin] = useState(-1);
  const [cookies,removeCookie] = useCookies(['name','token']);

  const handleLogin = () => {
    authenticate((auth) => {
      setIsShowLogin(auth);
      console.log('useEffect-->handleLogin');
      console.log(cookies.token);
      console.log(cookies.name);
      console.log('useEffect-->handleLogin');
    });
    //setIsShowLogin(authenticate());
  };

  useEffect(() => {
    console.log('useEffect');
    console.log(cookies.token);
    console.log(cookies.name);
    if (cookies.token) {
      sessionStorage.setItem('userName', cookies.name);
      sessionStorage.setItem('token', cookies.token);
      //setIsShowLogin(true);
      console.log('useEffect-->getcookies');
    }

    //console.log('app useEffect');
    // else {
    authenticate((auth) => {
      setIsShowLogin(auth);
      if(auth==0)
      {
        removeCookie('name',{ path: '/' });
        removeCookie('token',{ path: '/' });
        console.log('useEffect->removeCookie');
      }
      //console.log('authenticate isShowLogin=' + isShowLogin);
    });

    //console.log('isShowLogin=' + isShowLogin);
    // }
  }, []);

  // const toke=document.cookie.
  return (
    <div className="App">
      <BrowserRouter>
        {/* <MainTitle showLogin={isShowLogin} setShowLoginCallback={handleLogin} /> */}
        <ResponsiveAppBar showLogin={isShowLogin} setShowLoginCallback={handleLogin} />
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route path="/index" element={<MainPage />} />
          <Route path="/produce" element={<ProducePage />} />
          <Route path='/serive' element={
            <PrivateRoute authvalue={isShowLogin}>
              <SerivePage authvalue={isShowLogin}/>
            </PrivateRoute>
          } />
          <Route path={"/downLoad"} element={
            <PrivateRoute authvalue={isShowLogin}>
              <DownLoadPage />
            </PrivateRoute>
          } />
          <Route path="/about" element={<AboutPage />} />
          <Route path='/register' element={<RegisterPage handleClose={() => { }} />} />
          <Route path='/login' element={<LoginPage setShowLoginCallback={handleLogin} />} />
          <Route path={"/modifypassword"} element={
            <PrivateRoute authvalue={isShowLogin}>
              <ModifyPassword />
            </PrivateRoute>
          } />
          <Route path={"/activate"} element={<Activate />} />
          <Route path={"/resetpassword"} element={<ResetPassword />} />
          <Route path={"/usermanagement"} element={
            <PrivateRoute authvalue={isShowLogin}>
              <UserManagement />
            </PrivateRoute>
          } />
          <Route path={"/devicemanagement"} element={
            <PrivateRoute authvalue={isShowLogin}>
              <DeviceManagement />
            </PrivateRoute>
          } />
          {/* <Route Path={"/usermanagement"} element={<UserManagement />} />
          <Route Path={"/devicemanagement"} element={<DeviceManagement />} /> */}
          <Route path='/*' element={<Navigate to={'/'} />} />
        </Routes>
      </BrowserRouter>
      <FootPage />
      {/* <MainPage /> */}
      {/* <ProducePage/>
      <DownLoadPage/>
      <AboutPage /> 
      <FootPage /> */}
    </div>
  );
}

export default App;
