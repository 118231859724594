import './MainPage.css'
import React from 'react';
import ProducePage from './ProducePage/ProducePage';
import AboutPage from './AboutPage/AboutPage';

function MainPage() {
    return (
        <div>
            <ProducePage />
	    {/*<AboutPage />*/}
        </div>
    )
}

export default MainPage;
