// // login.js
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AlertDialog from '../public/AlertDialog';
import useForm from "../public/useForm";
import { validator } from "../public/Validator";
import { Navigate } from "react-router-dom";
import { authenticate } from '../public/PrivateRouter';
import { useCookies } from 'react-cookie';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const theme = createTheme();

export default function SignIn({ setShowLoginCallback }) {
  const [alert, setAlert] = React.useState(false);
  const [alertInfo, setAlertInfo] = React.useState("");

  const [isSubmit, setIsSubmit] = React.useState(false);

  const [cookies, setCookie,removeCookie] = useCookies(['name','token']);

  const [remember, setRemember] = React.useState(false);

  async function CheckPermise(data) {
    const requestOptions = {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data)
    };

    await fetch(process.env.REACT_APP_BASEURL + "/api/users/checkpermise", requestOptions)
      .then(res => {
        if (res.status !== 200) {
          {
            console.log("not 200 error")
          }
        } else {
          res.json().then(repData => {
            console.log(repData);
            setAlertInfo(repData.message);
            setAlert(true);
            if (repData.code == '200') {
              sessionStorage.setItem("userName", data.email);
              sessionStorage.setItem("token", repData.token);
              console.log('登陆成功！！！');
              console.log(repData);
              console.log("userName:" + sessionStorage.getItem("userName"));
              console.log("token:" + sessionStorage.getItem("token"));

              if (remember) {
                setCookie('name', data.email, { path: '/' });
                setCookie('token', repData.token, { path: '/' });
                console.log('login-->setCookie');
              }
              else
              {
                removeCookie('name',{ path: '/' });
                removeCookie('token',{ path: '/' });
                console.log('login-->removeCookie');
              }

              console.log(cookies);

              // document.cookie='token=${repData.token}';
              //setIsSubmit(authenticate());
              //this.props.history.push("/index");
            }
            //state.name='';
          })
        }
      }, error => {
        console.log(error)
      })
  }

  const initState = {
    email: "",
    password: ""
  };

  const submit = () => {
    console.log("Submited");
    var postData = { email: state.email.toLowerCase(), password: state.password };
    CheckPermise(postData);
    console.log("Submited end");
    // Navigate({to:'/'});
    //<Navigate to ="/"/>
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    countryCode
  } = useForm({
    initState,
    callback: submit,
    validator
  });

  return (
    <ThemeProvider theme={theme}>
      <AlertDialog
        alertstate={alert}
        handlechange={() => {
          setAlert(false);
          
          authenticate((autoValue) => {
            if (autoValue) {
              setIsSubmit(true);
              setShowLoginCallback();
            }
            else {
              setIsSubmit(false);
            }
          });
        }}
        alertinfo={alertInfo}
        isOnClose={false}
      />

      {isSubmit && (<Navigate to='/' />)}
      <Box display='flex' sx={{ minHeight: '89vh', width: '100vw' }} alignItems='center' justifyContent='center'>
        <Paper elevation='3'>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                // marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 5,
                mb: 5,
              }}
            >
              <Avatar sx={{ bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                登陆
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="邮箱地址"
                  name="email"
                  autoComplete="email"
                  defaultValue={state.email}
                  onChange={handleChange}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  onBlur={handleBlur}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="密码"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  defaultValue={state.password}
                  onChange={handleChange}
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked={remember} onChange={(event) => {
                    setRemember(event.target.checked);
                  }} color="primary" />}
                  label="记住登陆信息"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  登 陆
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/resetpassword" variant="body2">
                      忘记密码?
                    </Link>
                  </Grid>
                  <Grid item xs>
                    <Link href="/register" variant="body2">
                      没有账号? 请注册
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
          </Container>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
