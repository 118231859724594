import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import AlertDialog from './public/AlertDialog';
import { useCookies } from 'react-cookie';
import { AddBox } from '@mui/icons-material';

const pages = [{ name: '产品', href: '/produce' }, { name: '支持', href: '/serive' }, { name: '下载', href: '/download' },
{ name: '关于', href: '/about' }];

const manager_pages = [{ name: '产品', href: '/produce' }, { name: '支持', href: '/serive' }, { name: '下载', href: '/download' },
{ name: '设备管理', href: '/devicemanagement' }, { name: '关于', href: '/about' }];

const settings = [{ name: '登陆', href: '/login' }, { name: '注册', href: '/register' }];
const loginedSettings = [{ name: '修改密码', href: '/modifypassword' }, { name: '退出登陆', href: null }];
const manager_loginedSettings = [{ name: '修改密码', href: '/modifypassword' }, { name: '用户管理', href: '/usermanagement' },
{ name: '退出登陆', href: null }];

const ResponsiveAppBar = ({ showLogin, setShowLoginCallback }) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const [alert, setAlert] = React.useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['name','token'])

    // const loginOut = () => {
    //     setAlert(true);
    //   }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);

    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleUserMenu = (event) => {
        if (event.target.textContent === '退出登陆') {
            setAlert(true);
        }
    }

    return (
        <AppBar id='appBar' position="static">
            <AlertDialog
                alertstate={alert}
                handlechange={() => {
                    setAlert(false);
                    sessionStorage.clear();

                    removeCookie('name', { path: '/' });
                    removeCookie('token', { path: '/' });

                    setShowLoginCallback();
                }}
                handleCancel={() => {
                    setAlert(false);
                }}
                alertinfo="是否注销登陆"
                isOnClose={false}
            />
            <Container maxWidth="xl">
                <Toolbar disableGutters id='toolBar' sx={{ height: '100%' }}>
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <img src="leimode.png" className="Leimode-logo" width='70px' alt="logo" />
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LEIMODE
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {showLogin == 2 ? (
                                manager_pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <Button href={page.href}>{page.name}</Button>
                                    </MenuItem>
                                ))
                            ) : (
                                pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <Button href={page.href}>{page.name}</Button>
                                    </MenuItem>
                                ))
                            )
                            }
                        </Menu>
                    </Box>
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <img src="leimode.png" className="Leimode-logo" width='70px' alt="logo" />
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LEIMODE
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {showLogin == 2 ? (manager_pages.map((page) => (
                            <Button
                                key={page.name}
                                href={page.href}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))) : (pages.map((page) => (
                            <Button
                                key={page.name}
                                href={page.href}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        )))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {/* <Tooltip title="账户"> */}
                        <IconButton onClick={handleOpenUserMenu} size="large" sx={{ p: 0 }} color="inherit">
                            <AccountCircle sx={{ display: showLogin ? 'flex' : 'none', color: 'inherit' }} />
                            {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                            <AccountCircleOutlined sx={{ display: showLogin ? 'none' : 'flex', color: 'inherit' }} />
                            <Typography variant="button" sx={{ display: { xs: 'none', md: 'flex' }, color: 'inherit' }} >
                                {sessionStorage.getItem('userName')}
                            </Typography>
                        </IconButton>
                        {/* </Tooltip> */}
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {showLogin ? (showLogin == 2 ? (
                                manager_loginedSettings.map((setting) => (
                                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                        <Button onClick={handleUserMenu} href={setting.href}>{setting.name}</Button>
                                    </MenuItem>
                                ))) : loginedSettings.map((setting) => (
                                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                        <Button onClick={handleUserMenu} href={setting.href}>{setting.name}</Button>
                                    </MenuItem>
                                ))
                            ) : (
                                settings.map((setting) => (
                                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                                        <Button href={setting.href}>{setting.name}</Button>
                                    </MenuItem>
                                ))
                            )}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
