import './DownloadPage.css'
import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Typography, Link, Divider, Box, Container, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useCookies } from 'react-cookie';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import { ContactlessOutlined } from '@mui/icons-material';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function DownLoadPage() {
  //const { data }=useQuery(getUsers);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [install, setInstall] = useState(false);
  const [activation, setActivation] = useState(false);
  const [resetkey, setResetkey] = useState(false);

  const [state_user_name, setNameState] = useState("");
  const [state_user_password, setPasswordState] = useState("");
  const [state_user_remarks, setRemarksState] = useState("");

  const [iatValue, setIatValue] = useState(0);
  const [expValue, setExpValue] = useState(0);

  const [timerCount, setTimerCount] = useState(5);

  const baseFilesUrl = process.env.REACT_APP_BASEURL + '/api/downloads/handydraw_files?id=' + sessionStorage.getItem('token') + '&filename=';
  const baseFilesInfoUrl = process.env.REACT_APP_BASEURL + '/api/downloads/handydraw_files_info?id=' + sessionStorage.getItem('token') + '&filename=';

  //const [error, setError] = useState(null);

  const [fileInfo, setFileInfo] = useState({
    data: [
      {
        name: 'IOS 系统', data: [
          // { name: '绘图软件安转二维码', createtime: '2022/10/3' },
          // { name: '绘图软件激活二维码', createtime: '2022/10/3' },
          { name: 'HandyDrawAssistant安装包', filename: '/packages/Install_HandyDraw_Win32Release_online.rar' },
          { name: 'HandyDrawAssistant说明书', filename: '/manuals/HandyDrawAssistant.pdf' }
        ]
      },
      {
        name: 'ANDROID 系统', data: [
          { name: 'HandyDrawAssistant安装包', filename: '/packages/InstallHandyDrawForAndroid_Win.rar' },
          { name: 'HandyDraw-Apk在线安装', filename: '/packages/app-release.apk' }
        ]
      },
      {
        name: 'WINDOWS 系统', data: [
          { name: '道路交通事故现场绘图(试用版)', filename: '/packages/HandyDraw2023(试用版).rar' },
          { name: '道路交通事故现场绘图', filename: '/packages/Install_HandyDrawImageForCarWin7_Win32Release.rar' },
	  { name: '道路交通事故现场绘图(蒙文版)', filename: '/packages/Install_HandyDrawImageForCarWin7_MW_Win32Release.rar' },
          { name: '道路交通事故现场绘图说明书', filename: '/manuals/WIN摄影测量说明书.doc' },
          { name: '道路交通事故现场三维模拟', filename: '/packages/Install_HandyRenderOpengl_Win32Release.rar' },
          { name: '道路交通事故现场三维模拟说明书', filename: '/manuals/交通事故现场三维模拟软件说明书.doc' }
        ]
      },
      {
        name: '最新发布', data: [
         { name: '道路交通事故AI智能勘查 HandyVisionAssistant—Android—(试用版)', filename: '/packages/HandyVisionForAndroidAssistant.rar' }
	 // { name: '道路交通事故勘查——windows_x64平台(试用版)', filename: '/packages/InstallHandyDocuments_win_1.0.1.rar' },
         // { name: '道路交通事故勘查——macOs_arm64平台(试用版)', filename: '/packages/InstallHandyDocuments_macos_1.0.1.rar' },
         // { name: '道路交通事故勘查——linux_x64平台(试用版)', filename: '/packages/InstallHandyDocuments_linux_1.0.1.rar' },
         // { name: '道路交通事故勘查——ios平台(试用版)', filename: '/packages/InstallHandyDocuments_ios_1.0.1.rar' },
         // { name: '道路交通事故勘查——android平台(试用版)', filename: '/packages/InstallHandyDocuments_android_1.0.1.rar' }
        ]
      }
    ]
  });

  function clickButton() {
    sessionStorage.clear();
  }

  const latestCount = useRef(timerCount);

  useEffect(() => {
    setTimerCount(expValue - Math.floor(Date.now() / 1000));

    latestCount.current = expValue - Math.floor(Date.now() / 1000);
  }, [timerCount]);

  var isFetch = false;
  const updateList = () => {
    if (!isFetch) {
      isFetch = true;

      setTimerCount(50);
      const timer = setInterval(() => {
        console.log(latestCount.current);
        if (latestCount.current <= 0) {
          clearInterval(timer);
          setActivation(false);
          setResetkey(false);
          return;
        }
        setTimerCount(c => c - 1);
      }, 1000);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=utf-8',
        },
        // body: JSON.stringify(data)
      };

      //setIsLoaded(true);

      fetch(process.env.REACT_APP_BASEURL + '/api/downloads/activation_qrcode_effectivetime?id=' + sessionStorage.getItem('token'))
        .then(res => res.json())
        .then((result) => {
          setIatValue(result.iat);
          setExpValue(result.exp);
          setTimerCount(result.exp - Math.floor(Date.now() / 1000));
        },
          (error) => {
            console.log(error);
          });

      fileInfo.data.map((item) => (
        item.data.map((item_item) => (
          fetch(baseFilesInfoUrl + item_item.filename, requestOptions)
            .then(res => res.json())
            .then(
              (result) => {
                setIsLoaded(true);
                var fileInfoEx = { ...fileInfo };
                item_item.createtime = result.createtime;
                item_item.size = result.size;
                setFileInfo(fileInfoEx);
              },
              (error) => {
                // sessionStorage.clear();
              }
            )
        ))
      ));
    }
  };

  useEffect(updateList, []);

  //const list = { name: '', filename: '', createtime: '', size: '' };

  if (error) {
    // return <div>Error: {error.message}</div>;
    return <Box minHeight='89vh'>发生错误啦！请尝试重新登陆...</Box>
  } else if (!isLoaded) {
    return <Box minHeight='89vh'>Loading...</Box>;
  } else {
    return (
      <Box display='flex' flexDirection='row' justifyContent='center' minHeight='89vh' alignItems='center'>
        {/* <Box mt='100px' mb='100px'> */}
        <Box width='80%' sx={{ mt: 0 }}>
          <Box>
            <Divider variant="middle">{fileInfo.data[0].name}</Divider>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='center' >
            <Box >
              <Box display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href='#' onClick={(event) => { setInstall(!install) }}>绘图软件安转二维码</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:2022/11/11 05:08:38 &nbsp;&nbsp; 有效期:永远</Typography>
              </Box>
              <Box display={install ? 'flex' : 'none'} justifyContent='center' sx={{ mt: 1 }}>
                <img height='300px' width='300px' src={process.env.REACT_APP_BASEURL + '/api/downloads/install_qrcode?id=' + sessionStorage.getItem('token')} />
              </Box>
              <Box display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href='#' onClick={(event) => { timerCount ? setActivation(!activation) : setActivation(false) }}>绘图软件激活二维码</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:{new Date(iatValue * 1000).toLocaleString()}  &nbsp;&nbsp; 有效期:{Math.floor(timerCount / 60)}分{timerCount % 60}秒</Typography>
              </Box>
              <Box display={activation ? 'flex' : 'none'} flexDirection='column' justifyContent='center' alignItems='center' sx={{ mt: 1 }}>
                <img height='300px' width='300px' src={process.env.REACT_APP_BASEURL + '/api/downloads/activation_qrcode?id=' + sessionStorage.getItem('token')} />
                <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{ mt: 1 }}>
                  <Typography variant="body2">激活步骤:</Typography>
                  <Typography variant="body2">1、利用IPAD相机扫描二维码后打开链接,绘图软件会打开然后下载描述文件。</Typography>
                  <Typography variant="body2">2、进入IPAD的 “设置” ⇨ “通用” ⇨ “VPN与设备管理”,打开下载的描述文件并安装,完成激活。</Typography>
		  <Typography variant="body2">● 必须先扫描安装二维码安装绘图软件，安装完成后才可以扫描激活二维码，否则提示“未找到可用数据”错误。</Typography>
                  <Typography variant="body2">● 二维码有效期为登录后30分钟内,关闭浏览器或退出登录不影响有效期。</Typography>
                  <Typography variant="body2">● 再次登录会造成之前生成二维码立即失效,即使在30分钟内。</Typography>
                  <Typography variant="body2">● 下载的描述文件有效期1分钟,过期会提示”描述文件安装失败“。下载后需要立即进行安装。</Typography>
                  <Typography variant="body2">● 可利用IPAD的safari浏览器打开,长按二维码选择《在“交通事故绘图”中打开》，进行激活。</Typography>
                </Box>
              </Box>

		  {/* <Box display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href='#' onClick={(event) => { timerCount ? setResetkey(!resetkey) : setActivation(false) }}>绘图软件抹掉激活二维码</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:{new Date(iatValue * 1000).toLocaleString()}  &nbsp;&nbsp; 有效期:{Math.floor(timerCount / 60)}分{timerCount % 60}秒</Typography>
              </Box>
              <Box display={resetkey ? 'flex' : 'none'} flexDirection='column' justifyContent='center' alignItems='center' sx={{ mt: 1 }}>
                <img height='300px' width='300px' src={process.env.REACT_APP_BASEURL + '/api/downloads/resetkey_qrcode?id=' + sessionStorage.getItem('token')} />
              </Box>*/}

	      {/*{fileInfo.data[0].data.map((item) => (<Box key={fileInfo.data[0].data.indexOf(item)} display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href={baseFilesUrl + item.filename}>{item.name}</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:{new Date(item.createtime).toLocaleString()} &nbsp;&nbsp;
                  大小:{Math.floor(item.size / 1024 / 1024 + 0.5) == 0 ?
                    Math.floor(item.size / 1024 + 0.5) + 'K' :
                    Math.floor(item.size / 1024 / 1024 + 0.5) + 'M'}
                </Typography>
              </Box>))}*/}
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Divider variant="middle">{fileInfo.data[1].name}</Divider>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='center' >
            <Box>
              {fileInfo.data[1].data.map((item) => (<Box key={fileInfo.data[1].data.indexOf(item)} display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href={baseFilesUrl + item.filename}>{item.name}</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:{new Date(item.createtime).toLocaleString()} &nbsp;&nbsp;
                  大小:{Math.floor(item.size / 1024 / 1024 + 0.5) == 0 ?
                    Math.floor(item.size / 1024 + 0.5) + 'K' :
                    Math.floor(item.size / 1024 / 1024 + 0.5) + 'M'}
                </Typography>
              </Box>))}
	  {/*<Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{ mt: 1 }}>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>PKG:5.0.6(11) LIB:6.2.0 FILE:1.6</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>1、增加勘查笔录的电子签名显示。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>2、实景俯瞰记录图“比例尺”、“高度”信息文字描边，增强文字在图片上的显示效果。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>3、修复实景俯瞰记录图的“是否显示比例尺”的控制错误。</Typography>

		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>&nbsp;</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>PKG:5.0.5(10) LIB:6.2.0 FILE:1.6</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>1、增加实景图层下的标注线颜色与宽度的设置。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>2、修改实景图层下图痕迹绘制方式。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>3、修改已知BUG。</Typography>

		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>&nbsp;</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>PKG:5.0.4(9) LIB:6.1.9 FILE:1.6</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>1、修复交通现象预览图不显示。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>2、修改打印图纸电子签名的比例。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>3、修改打印模板位置错误。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>4、增加实景俯瞰记录图的高度设置。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>5、增加标注样式独立设置。</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>6、修改已知BUG。</Typography>

		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>&nbsp;</Typography>
		  <Typography variant="overline" style={{ lineHeight: '1.2' }}>PKG:5.0.3(8) LIB:6.1.8 FILE:1.5</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>1、应用国标字体，打印模版及笔录。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>2、图符编号文字整体剧中，可设置编号位于图符的位置。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>3、应用动态高清图符预览图标。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>4、优化资源文件，减少APK大小。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>5、增加图形选择后的轮廓线。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>6、文件结构调整，与IOS版本相同模式。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>7、增加多边形、方向箭头、斑马线的属性框。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>8、修改本地图纸、预制图纸、远程图纸的列表排序策略，最后添加的排在最上。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>9、增加本地图纸添加备注。</Typography>
                  <Typography variant="overline" style={{ lineHeight: '1.2' }}>10、增加对FTP服务的支持。设置中添加对FTP相关设置；远程图纸下拉更新；远程图纸下载到本地图纸；本地图纸上传。</Typography>
                </Box>*/}
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Divider variant="middle">{fileInfo.data[2].name}</Divider>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='center' >
            <Box>
              {fileInfo.data[2].data.map((item) => (<Box key={fileInfo.data[2].data.indexOf(item)} display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href={baseFilesUrl + item.filename}>{item.name}</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:{new Date(item.createtime).toLocaleString()} &nbsp;&nbsp;
                  大小:{Math.floor(item.size / 1024 / 1024 + 0.5) == 0 ?
                    Math.floor(item.size / 1024 + 0.5) + 'K' :
                    Math.floor(item.size / 1024 / 1024 + 0.5) + 'M'}
                </Typography>
              </Box>))}
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Divider variant="middle">{fileInfo.data[3].name}</Divider>
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='center' >
            <Box>
              {fileInfo.data[3].data.map((item) => (<Box key={fileInfo.data[2].data.indexOf(item)} display='flex' justifyContent='flex-start' sx={{ mt: 1 }}>
                <Link href={baseFilesUrl + item.filename}>{item.name}</Link>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布时间:{new Date(item.createtime).toLocaleString()} &nbsp;&nbsp;
                  大小:{Math.floor(item.size / 1024 / 1024 + 0.5) == 0 ?
                    Math.floor(item.size / 1024 + 0.5) + 'K' :
                    Math.floor(item.size / 1024 / 1024 + 0.5) + 'M'}
                </Typography>
              </Box>))}
            </Box>
          </Box>
        </Box>
        {/* <h2>{cookies.name}</h2>
        <h2>{cookies.token}</h2>

        <ul>
          {data.data.map((user) => (
            <h3 key={user.id}> {user.id} / {user.name} / {user.email} / {user.password} / {user.create_time}</h3>
          ))}
        </ul> */}
      </Box>
    );
  }
  // return <div className="display-Download ">下载页面</div>
}

export default DownLoadPage;
